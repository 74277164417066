#root,
body,
html {
  height: 100%;
}

body {
  margin: 0;
}

.ant-layout {
  display: flex;
  width: 100%;
  min-height: 100%;
}